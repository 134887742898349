import React from 'react';
import { Provider } from 'react-redux';

import SEO from '../components/seo';
import store from '../store';
import SummaryView from '../components/SummaryView';

const Summary = () => (
  <Provider store={store}>
    <SEO title="Summary View" />
    <SummaryView />
  </Provider>
);

export default Summary;
