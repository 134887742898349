import React, { useState } from 'react';
import * as d3 from 'd3';
import * as R from 'ramda';

/**
 * Returns a sum of all of the metric values that are in the provided tornadoRowArray.
 *
 * I use this when comparing two tornadoRowArrays.  I assume that if the sum of all the metric
 * values are equal, then the arrays are equal.
 *
 * @sig tornadoDataRow -> Number
 * @author Keith Elliott, 9/30/2020
 */
export const sumAllMetricValues = R.compose(
  R.sum(),
  //R.map(d => d.metricOptimistic + d.metricPessimistic),
  R.map(d => d.metricOptimistic),
  R.ifElse(R.equals(undefined), R.always([]), R.identity)
);

export const isTornadoRowArrayEqual = (tornadoRowArray1, tornadoRowArray2) => (
  sumAllMetricValues(tornadoRowArray1) === sumAllMetricValues(tornadoRowArray2)
);

const formatComma = d3.format(",")

function plotTornado(myRef, tornadoRowArray, metricBase, myWidth, myHeight, xAxisLabel,
  title, baseCaseDescription, subTitle) {

  if (tornadoRowArray === undefined || !Array.isArray(tornadoRowArray) || tornadoRowArray.length === 0) {

    d3.select(myRef)
      .append("p")
      .text('Tornado data is not yet defined.');

    // Return and stop the function.  Cannot proceed with incorrect data structure.
    return;
  }

	// Dimensions
  const margin = { top: 80, right: 80, bottom: 38, left: 290 };
  const width = myWidth - margin.right - margin.left;
  const height = myHeight - margin.top - margin.bottom;
  const labelYshift = 95;
  const labelXshiftVertical = 36;
  const labelTornadoEndOptimisticShift = 8;
  const labelTornadoEndPessimisticShift = 8;
  const labelTornadoBaseShiftX = 10;
  const labelTornadoBaseShiftY = 4;
  const backgroundBoxCharLength = 7; // per-character width that is multiplied by the character length
  const backgroundBoxPadding = 3;
  const backgroundCharHeight = 11;
	const paddingBars = .4; // decimal number used by scaleBand.paddingInner().  Represents the portion of the band that is white space.

  const calcMetricSpread = ( tornadoRow ) =>
    R.prop('metricOptimistic', tornadoRow) - R.prop('metricPessimistic', tornadoRow);

  const byMetricSpread = R.ascend(calcMetricSpread);

  const tornadoSorted = R.sort(byMetricSpread, tornadoRowArray);

  const tornadoNames = R.pluck('rowName', tornadoSorted)

  // Y Scale
  const tornadoScale = d3.scaleBand()
    .domain(tornadoNames)
    .rangeRound([height, 0])
    .paddingInner(paddingBars);

  // X Scale
  const xScale = d3.scaleLinear()
    .domain([0, d3.max(tornadoSorted, function(d) { return d.metricOptimistic; })])
    .range([0, width]);

  // Draw base
  const svg = d3.select(myRef)
    .append("svg")
    .attr('class', 'tornado-chart')
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  // Draw title
  const header = svg
    .append("g")
    .attr("class", "plot-title")  // KTE, 8/1/2020:  changed class name from header
    // .attr("transform", `translate(0, ${-margin.top * 0.3})`)
    .attr("transform", `translate(0, ${-margin.top})`)
    .append("text")
      .attr("text-anchor", "start")
      .attr("y", '1.1em');

  const headerMain = header.append("tspan").attr('class', 'plot-main-title').text(title);

  // Draw subtitle
  const headerSub = header
    .append("tspan")
    .attr('class', 'plot-sub-title')
    .text(baseCaseDescription + metricBase)
    .attr('x', '0')
    .attr('y', '3.0em');

  // Draw sub-sub-title
  const headerSubSub = header
    .append("tspan")
    .attr('class', 'plot-sub-sub-title')
    .text(subTitle)
    .attr('x', '0')
    .attr('y', '5.0em');

  // Draw x axis
  const xAxis = d3.axisBottom(xScale)
    .tickSizeInner(-height);

  svg.append('g')
    .attr("class", "x axis")
    .attr('transform', `translate(0, ${height})`)
    .call(xAxis);

  // Draw X Axis Label
  svg
    .append("g")
    .attr("class", "x axisLabel")
    .attr("transform", `translate(${width / 2}, ${height})`)
    .append("text")
      .attr('x', '0')
      // .attr('y', '2em')
      .attr('y', labelXshiftVertical)
      .attr("text-anchor", "middle")
      .text(xAxisLabel);

  // Dray Y Axis
  //  No Y Axis!  Just the following labels...

  // Draw Y Axis Labels
  svg
    .append('g')
    .attr('class', 'y axisLabel')
    .attr("transform", `translate(${-margin.left}, 0)`)
    .selectAll('labels')
    .data(tornadoSorted)
    .enter()
    .append('text')
    .attr('x', '0')
    .attr('y', (d) => tornadoScale(d.rowName) + tornadoScale.bandwidth()*.7)
    .text((d) => d.rowName);


  // Draw horizontal bars showing the metric range above the base
  svg
    .append('g')
    .attr('class', 'bar optimistic')
      .selectAll('plot-bar-optimistic')
      .data(tornadoSorted)
      .enter()
      .append('rect')
        .attr('x', (d) => xScale(metricBase))
        .attr('y', (d) => tornadoScale(d.rowName))
        .attr('height', tornadoScale.bandwidth())
        .attr('width', (d) => xScale(d.metricOptimistic - metricBase))

  // Draw background boxes around the optimistic value labels
  svg
    .append('g')
    .attr('class', 'background optimistic')
      .selectAll('plot-label-background-optimistic')
      .data(tornadoSorted)
      .enter()
      .append('rect')
        .attr('x', d => xScale(d.metricOptimistic) + labelTornadoEndOptimisticShift - backgroundBoxPadding)
        .attr('y', d => tornadoScale(d.rowName) + tornadoScale.bandwidth()*.55 - backgroundCharHeight / 2 - backgroundBoxPadding * 1)
        .attr('width', d => formatComma(d.reducedPropertyValueOptimistic).length * backgroundBoxCharLength + backgroundBoxPadding * 2)
        .attr('height', backgroundCharHeight + backgroundBoxPadding * 2)

  // Draw text labels for optimistic row ends
  svg
    .append('g')
    .attr('class', 'label optimistic')
      .selectAll('plot-label-optimistic')
      .data(tornadoSorted)
      .enter()
      .append('text')
        .attr('x', (d) => xScale(d.metricOptimistic) + labelTornadoEndOptimisticShift)
        .attr('y', (d) => tornadoScale(d.rowName) + tornadoScale.bandwidth()*.7)
        .text(d => formatComma(d.reducedPropertyValueOptimistic))

  // Draw horizontal bars showing the metric range below the base
  svg
    .append('g')
    .attr('class', 'bar pessimistic')
      .selectAll('plot-tornado-pessimistic')
      .data(tornadoSorted)
      .enter()
      .append('rect')
        .attr('x', (d) => xScale(d.metricPessimistic))
        .attr('y', (d) => tornadoScale(d.rowName))
        .attr('height', tornadoScale.bandwidth())
        .attr('width', (d) => xScale(metricBase - d.metricPessimistic))

  // Draw background boxes around the pessimistic value labels
  svg
    .append('g')
    .attr('class', 'background pessimistic')
      .selectAll('plot-label-background-pessimistic')
      .data(tornadoSorted)
      .enter()
      .append('rect')
        .attr('x', d => xScale(d.metricPessimistic)
          - labelTornadoEndPessimisticShift
          - (formatComma(d.reducedPropertyValuePessimistic).length * backgroundBoxCharLength + backgroundBoxPadding * 1)
        )
        .attr('y', d => tornadoScale(d.rowName) + tornadoScale.bandwidth()*.55 - backgroundCharHeight / 2 - backgroundBoxPadding * 1)
        .attr('width', d => formatComma(d.reducedPropertyValuePessimistic).length * backgroundBoxCharLength + backgroundBoxPadding * 2)
        .attr('height', backgroundCharHeight + backgroundBoxPadding * 2)

  // Draw text labels for pessimistic row ends
  svg
    .append('g')
    .attr('class', 'label pessimistic')
      .selectAll('plot-label-pessimistic')
      .data(tornadoSorted)
      .enter()
      .append('text')
        .attr('x', (d) => xScale(d.metricPessimistic) - labelTornadoEndPessimisticShift)
        .attr('y', (d) => tornadoScale(d.rowName) + tornadoScale.bandwidth()*.7)
        .attr("text-anchor", "end")
        .text(d => formatComma(d.reducedPropertyValuePessimistic))

  // Draw background boxes around the base values
  svg
    .append('g')
    .attr('class', 'background baseMetric')
      .selectAll('plot-label-background-base')
      .data(tornadoSorted)
      .enter()
      .append('rect')
        .attr('x', xScale(metricBase) + labelTornadoBaseShiftX - backgroundBoxPadding)
        .attr('y', d => tornadoScale(d.rowName) - labelTornadoBaseShiftY - backgroundCharHeight - backgroundBoxPadding * 1)
        .attr('width', d => formatComma(d.reducedPropertyValueBase).length * backgroundBoxCharLength + backgroundBoxPadding * 2)
        .attr('height', backgroundCharHeight + backgroundBoxPadding * 2)

  // Draw text labels for base values
  svg
    .append('g')
    .attr('class', 'label baseMetric')
      .selectAll('plot-label-base')
      .data(tornadoSorted)
      .enter()
      .append('text')
        .attr('x', xScale(metricBase) + labelTornadoBaseShiftX)
        .attr('y', (d) => tornadoScale(d.rowName) - labelTornadoBaseShiftY)
        .text(d => formatComma(d.reducedPropertyValueBase))

  // Draw a line connecting the baseline to the base label
  svg
    .append('g')
    .attr('class', 'label baseMetric connect')
    .selectAll('plot-label-connecting-line')
    .data(tornadoSorted)
    .enter()
    .append('line')
      .attr('x1', xScale(metricBase))
      .attr('x2', xScale(metricBase) + labelTornadoBaseShiftX - backgroundBoxPadding)
      .attr('y1', (d) => tornadoScale(d.rowName) )
      .attr('y2', (d) => tornadoScale(d.rowName) - labelTornadoBaseShiftY)

  // Draw a vertical line at the base metric x position
  svg
    .append('g')
    .attr('class', 'baseMetric')
    .append('line')
      .attr('x1', xScale(metricBase))
      .attr('x2', xScale(metricBase))
      .attr('y1', 0)
      .attr('y2', height);
}

class PlotTornado extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    console.log('in componentDidMount, here is props:  ', this.props);
    plotTornado(this.myRef.current,
      this.props.tornadoRowArray,
      this.props.metricBase,
      this.props.width,
      this.props.height,
      this.props.xAxisLabel,
      this.props.title,
      this.props.baseCaseDescription,
      this.props.subTitle,
    );
  }

  componentDidUpdate(prevProps) {
    console.log('in componendDidUpdate.  Here is this.props.tornadoRowArray: ', this.props.tornadoRowArray);
    //if ( ! isTornadoRowArrayEqual(prevProps.tornadoRowArray, this.props.tornadoRowArray) ) {
    if ( true ) {
      d3.select(this.myRef.current).select("p").remove();
      d3.select(this.myRef.current).select("svg").remove();

      plotTornado(this.myRef.current,
        this.props.tornadoRowArray,
        this.props.metricBase,
        this.props.width,
        this.props.height,
        this.props.xAxisLabel,
        this.props.title,
        this.props.baseCaseDescription,
        this.props.subTitle,
      );
    }
  }

  render() {
    return (
      <div className="plot-tornado" ref={this.myRef} />
    );
  }
}

export default PlotTornado;
