import React from 'react';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link } from 'gatsby';

import { createTornado } from '../actions';

import PlotLineGraph from './PlotLineGraph';
import PlotTornado from './PlotTornado';

import {
  createPlotDataObjectArrayAllCases,
} from '../utils';

import logo from '../images/logo_hilcorp_big.png';

import '../styles/graphs.css';
import '../styles/summary.css';

const SummaryView = () => {
  const [localState, setLocalState] = useState(typeof window !== `undefined`
    ? JSON.parse(localStorage.getItem('localState'))
    : { results: {}, projectInputs: {}, tornado: {}, }
  );

  const { projectInputs, results, tornado } = localState;

  // Use useSelector Hook to pull from Redux store
  // const { subPage, cases, results, status, tornado, major } = useSelector((state) => ({
  //   subPage: state.navigation.subPage,
  //   cases: state.cases,
  //   results: state.results,
  //   status: state.status,
  //   tornado: state.tornado,
  //   major: state.projectInputs.major,
  // }), shallowEqual);
  //
  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  //   dispatch(createTornado(major, cases, results));
  // }, [major, cases, results])

console.log('in SummaryView, here is localState, updated on 10/1/2020:  ', localState);
console.log('in SummaryView, here is tornado, updated on 10/1/2020:  ', tornado);

//  const netIncomePV10PlotAllCases = createPlotDataObjectArrayAllCases(results, 'presentValue10');
  // const netInvestmentsPV10PlotAllCases = createPlotDataObjectArrayAllCases(results, 'capitalNetDiscounted10');

  const renderHeader = (title) => (
    <div className="header">
      <h1>{title}</h1>
      <img src={logo} style={{ width: '70px', height: '65px', marginTop: '0', marginBottom: '0'}} alt="" />
    </div>
  );

  return (
    <div>
    <section className="slide">
      {
        renderHeader('Sensitivity Analysis ' +
          (projectInputs?.projectName ? 'for ' + projectInputs.projectName : '')
        )
      }
      <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '0.1rem' }}>
      <PlotTornado
          tornadoRowArray={tornado}
          metricBase={results?.p50?.economicMetrics?.pvr10}
          baseCaseDescription='PVR10 of the P50 Unrisked Base Case is '
          width='990'
          height='540'
          xAxisLabel='PVR10 ($/$)'
          title='PVR10 Tornado Chart'
          subTitle='Each row displays the PVR10 range associated with varying a single variable around the P50 unrisked base case'
        />
      </div>
    </section>
      {/*
      <Link className="return-link" style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }} to="/" state={{ results }}>&larr; Back to HEConomics</Link>
      <section className="slide">
        <img src={logo} alt="" />
        <h1 className="title">ANS Reserves Summary</h1>
        <h2 className="author">Keith Elliott</h2>
        <h3 className="date">Mid-Year 2020</h3>
      </section>
      <section className="slide level2">
        {renderHeader('ANS Reserves Summary')}
        <table>
            <thead>
                <tr>
                    <th>Reserve Category</th>
                    <th>Oil & NGL <br /> (MMBO)</th>
                    <th>Gas <br /> (BCF)</th>
                    <th>BOE <br /> (MMBOE)</th>
                    <th>CAPEX <br /> (MM$)</th>
                    <th>ABND <br /> (MM$)</th>
                    <th>PV10 <br /> (MM$)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PDP</td>
                    <td>62</td>
                    <td>2</td>
                    <td>63</td>
                    <td>0</td>
                    <td>293</td>
                    <td>870</td>
                </tr>
                <tr>
                    <td>PNP</td>
                    <td>2</td>
                    <td>0</td>
                    <td>2</td>
                    <td>5</td>
                    <td>0</td>
                    <td>24</td>
                </tr>
                <tr>
                    <td>PUD</td>
                    <td>45</td>
                    <td>0</td>
                    <td>45</td>
                    <td>498</td>
                    <td>34</td>
                    <td>500</td>
                </tr>
                <tr>
                    <td>Total Proved</td>
                    <td>109</td>
                    <td>2</td>
                    <td>110</td>
                    <td>504</td>
                    <td>327</td>
                    <td>1394</td>
                </tr>
            </tbody>
        </table>
      </section>
      */}

{/*
      <section className="slide">
        {renderHeader('ANS Summary Plots')}
        <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '2rem' }}>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        </div>
      </section>
      <section className="slide">
        {renderHeader('ANS Oil & Gas Rate')}
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around', paddingTop: '2rem' }}>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        <div style={{ width: '50%', height: '50%' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='500'
            height='270'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
        </div>
      </section>
      <section className="slide">
        {renderHeader('ANS PUDs by PV10')}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', paddingTop: '2rem' }}>
        <PlotLineGraph
            plotDataArray={netIncomePV10PlotAllCases}
            width='800'
            height='600'
            xAxisLabel='Year'
            yAxisLabel='PV10 Net Income'
            yAxisType='cartesian'
            title='PV10 Net Income'
            subTitle='$/mo'
          />
        </div>
      </section>
      */}
    </div>
  );
};

export default SummaryView;
